import React from "react"
import styles from "../components/header.module.css"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => (
  <Header>
    <div className={styles.mainheading}>Aviva Melamed</div>
    <div className={styles.subheading}>Humanistic Integrative Counsellor</div>
    <div className={styles.box} />
    <div className={styles.menubox}>
      <ul className={styles.ul}>
        <li className={styles.li}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.li}>
          <Link to="/about">About Me</Link>
        </li>
        <li className={styles.li}>
          <Link to="/faq">FAQ</Link>
        </li>
        <li className={styles.li}>
          <Link to="/services">Services</Link>
        </li>
        <li className={styles.li}>
          <Link to="/contact">Contact Me</Link>
        </li>
      </ul>
    </div>
    <div className={styles.logo} />
    <div className={styles.faq}>Frequentyly Asked Questions</div>
    <div className={styles.faqtext}>
      <b>Will my sessions be confidential?</b>
      <br />
      <br />
      Confidentiality is of utmost importance and I hold by the BACP
      professional standards.
      <br />
      <br />
      <b>How long is the session?</b>
      <br />
      <br />
      A session is 50 minutes long.
      <br />
      <br />
      <b>What happens in the first session?</b>
      <br />
      <br />
      The first session will be an initial discussion to look at your goals and
      what you would like to get out of counselling. At the end of this initial
      session, you will be able to decide whether you would like to continue
      with further appointments.
      <br />
      <br />
      <b>How many sessions will I need?</b>
      <br />
      <br />
      I offer both long-term and short-term therapy. It is recommended to give
      yourself an initial 6 sessions, after which we can review your progress
      and work out the best theraputic plan for you.
      <br />
      <br />
      <b>How much do you charge?</b>
      <br />
      <br />
      I charge £55 per session.
      <br />
      <br />
      <b>Can I stop at any time?</b>
      <br />
      <br />
      Yes, but it is advisable to have an ending session.
      <br />
      <br />
      <b>How can I pay</b>
      <br />
      <br />
      Payment can be made in cash or by bank transfer. If you choose to pay by
      bank transfer the payment needs to be made before the session.
      <br />
      <br />
      <b>Do you have a cancellation policy</b>
      <br />
      <br />
      Should you need to cancel an appointment this needs to be done 3 days
      before your allocated slot, otherwise you will still be charged.
    </div>
  </Header>
)
